/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import MuiDialog from "@material-ui/core/Dialog";

const Dialog = styled(MuiDialog)({
  minWidth: 300,
  ".MuiDialog-paper": {
    minWidth: 300,
    maxWidth: 490,
    boxShadow: "none",
  },
});
export default Dialog;
