/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import MuiDialogContent from "@material-ui/core/DialogContent";

const DialogContent = styled(MuiDialogContent)({
  paddingTop: 20,
  paddingBottom: 24,
  lineHeight: 1.5,
  ".MuiDialogContentText-root": {
    lineHeight: 1.5,
    marginBottom: 0,
  },
});
export default DialogContent;
